import { PopupInfoVal } from "../interfaces/popup-info-val";

export class ObjPopupInfoVal implements PopupInfoVal{
  // può essere qualunque id
  id = 0;
  // esito per esempio quando ci sono tante risposte e si devono dividere
  esito = 0;
  url = '';
  pagina = '';
  // array di oggetti
  collection = [];
  // un oggetto {nome: '', ...}
  myobject: object | null = {};
  //es: url?id=3&nome=pippo
  queryParams_close = '';
  queryParams_go = '';
  //es: url/nome/nome/...
  array_path_close = [];
  array_path_go = [];
  // pulsanteGo: true o false (compare o meno il pulsante)
  pulsanteGo = false;
  testoPulsanteGo = '';

  /*public setPulsanteGo(val: boolean): void {
      this.pulsanteGo = val;
  }*/
}
